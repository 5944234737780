import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search projects…",
      "Welcome back": "Welcome back",
      "Personal Information": "Personal Information",
      Practicing: "Practicing",
      Survey: "Survey",
      "First name": "First name",
      "Last name": "Last name",
      Gender: "Gender",
      "Date of birth": "Date of birth",
      "Phone number": "Phone number",
      Email: "Email",
      Address: "Address",
      Region: "Region",
      District: "District",
      Ward: "Ward",
      Street: "Street",
      "Select a region": "Select a region",
      "Select a district": "Select a district",
      "Education level": "Education level",
      Primary: "Primary",
      Secondary: "Secondary",
      "Vocational training": "Vocational training",
      Diploma: "Diploma",
      Undergraduate: "Undergraduate",
      Postgraduate: "Postgraduate",
      "Employment status": "Employment status",
      Retired: "Retired",
      Employed: "Employed",
      "Self employed": "Self employed",
      "Not employed": "Not employed",
      "Identification type": "Identification type",
      "National Identification Card": "National Identification Card",
      "Driver's license": "Driver's license",
      Passport: "Passport",
      "Voter's Identification Card": "Voter's Identification Card",
      "Are you a member of the National Health Insurance Fund(NHIF)?":
        "Are you a member of the National Health Insurance Fund(NHIF)?",
      Yes: "Yes",
      No: "No",
      Continue: "Continue",
      //second step
      "Are you currently doing any form of Agriculture?":
        "Are you currently doing any form of Agriculture?",
      "Which of these fields in Agriculture are you participating":
        "Which of these fields in Agriculture are you participating",
      Farming: "Farming",
      Livestock: "Livestock",
      Fishing: "Fishing",
      "What activities are you involved in?":
        "What activities are you involved in?",
      "What regions do you practice the above activities in":
        "What regions do you practice the above activities in",
      "What challenges do you face?": "What challenges do you face?",
      "Crop production": "Crop production",
      "Livestock production": "Livestock production",
      "Fish production": "Fish production",
      Logistics: "Logistics",
      "Agricultural inputs": "Agricultural inputs",
      "Bucket seeds": "Bucket seeds",
      "Agricultural tools": "Agricultural tools",
      "Sales & Marketing": "Sales & Marketing",
      Previous: "Previous",
      "Which of these fields in Agriculture are you interested in?":
        "Which of these fields in Agriculture are you interested in?",
      "What activity do you wish to be invloved in?":
        "What activity do you wish to be invloved in?",
      "What region do you wish to practice in?":
        "What region do you wish to practice in?",
      "Nida number": "Nida number",
      "Passport number": "Passport number",
      "Voter's Identification number": "Voter's Identification number",
      Male: "Male",
      Female: "Female",
      "Livestock keeping": "Livestock keeping",
      Small: "Small",
      Medium: "Medium",
      Large: "Large",
      Cereal: "Cereal",
      Fruits: "Fruits",
      Vegetables: "Vegetables",
      Ingredients: "Ingredients",
      "Edible oil crops": "Edible oil crops",
      "Other commercial crops": "Other commercial crops",
      Agroforestry: "Agroforestry",
      "Root crops": "Root crops",
      Cows: "Cows",
      Goats: "Goats",
      Sheep: "Sheep",
      Chicken: "Chicken",
      Pigs: "Pigs",
      "Bee keeping": "Bee keeping",
      Other: "Others",
      "Salt water": "Salt water",
      "Fresh water": "Fresh water",
      "Pond breeding": "Pond fishing",
      Transport: "Transport",
      Storage: "Storage",
      Processing: "Processing",
      Markets: "Markets",
      "Production scale": "Production scale",
      "What activity do you wish to be involved in?":
        "What activity do you wish to be involved in?",
      "What region do you wish to practice at?":
        "What region do you wish to practice at?",
      "Production scale": "Production scale",
      "Livestock categories": "Select the categories in Livestock (Multiple)",
      "Farming categories": "Select the categories in Farming (Multiple)",
      "Fishing categories": "Select the categories in Farming (Multiple)",
      "Succesfully recorded": "Succesfully recorded",
      "Back to website": "Back to website",
      Success: "Success",
      Summary: "Summary",
      "NHIF member": "NHIF member",
      "Agricultural activities currently practicing":
        "Agricultural activities currently practicing",
      Category: "Category",
      Activities: "Activities",
      "Not practcing agriculture": "Not practcing agriculture",
      "Submit Information": " Submit Information",
      Specify: "Specify",
      "ID Number": "ID Number",
      "What farming activities are you interested in doing":
        "What farming activities are you interested in doing",
      "What livestock activities are you interested in doing":
        "What livestock activities are you interested in doing",
      "What fishing activities are you interested in doing":
        "What fishing activities are you interested in doing",
      "What farming activities do you do": "What farming activities do you do",
      "What livestock activities do you do":
        "What livestock activities do you do",
      "What fishing activities do you do": "What fishing activities do you do",
    },
  },
  sw: {
    translation: {
      "What fishing activities do you do": "Unafanya shughuli gani za uvuvi",
      "What livestock activities do you do":
        "Unafanya shughuli gani za ufugaji",
      "What farming activities do you do": "Unafanya shughuli gani za kilimo",
      "What fishing activities are you interested in doing":
        "Unapenda kufanya shughuli gani za uvuvi",
      "What livestock activities are you interested in doing":
        "Unapenda kufanya shughuli gani za ufugaji",
      "What farming activities are you interested in doing":
        "Unapenda kufanya shughuli gani za kilimo",
      "ID Number": "Nambari ya kitambulisho",
      Specify: "Bainisha",
      "Submit Information": "Wasilisha taarifa",
      "Not practcing agriculture": "Sijishughulishi na kilimo",
      Activities: "Shughuli",
      Category: "Kategoria",
      "Agricultural activities currently practicing":
        "Shughuli za kilimo unazofanya kwa sasa",
      "NHIF member": "Mwanachama wa NHIF",
      Summary: "Muhtasari",
      Success: "Imefanikiwa",
      "Succesfully recorded": "Imerikodiwa",
      "Back to website": "Rudi kwenye tovuti",
      "Livestock categories": "Chagua aina ya ufugaji (Nyingi)",
      "Farming categories": "Chagua aina ya ukulima (Nyingi)",
      "Fishing categories": "Chagua aina ya uvuvi (Nyingi)",
      "Production scale": "Kiwango cha uzalishaji",
      "What region do you wish to practice at?":
        "Je, ungependa kufanya mazoezi katika mkoa gani?",
      "What activity do you wish to be involved in?":
        "Je, ungependa kushirikishwa katika shughuli gani?",
      "Crop production": "uzalishaji wa mazao",
      "Production scale": "Kiwango cha uzalishaji",
      Transport: "Usafirishaji",
      Storage: "Hifadhi",
      Processing: "Usindikaji",
      Markets: "Masoko",
      "Salt water": "Maji chumvi",
      "Fresh water": "Maji baridi",
      "Pond breeding": "Uvuaji wa mabwawa",
      Cows: "Ng'ombe",
      Goats: "Mbuzi",
      Sheep: "Kondoo",
      Chicken: "Kuku",
      Pigs: "Nguruwe",
      "Bee keeping": "Ufugaji wa Nyuki",
      Other: "Mengineyo",
      Cereal: "Nafaka",
      Fruits: "Matunda",
      "Root crops": "Mazao ya mizizi",
      Agroforestry: "Kilimo cha miti",
      "Other commercial crops": "Mazao mengine ya biashara",
      Vegetables: "Mbogamboga",
      Ingredients: "Viungo",
      "Edible oil crops": "Mazao ya mafuta ya kula",
      Small: "Mdogo",
      Medium: "Kati",
      Large: "Kubwa",
      "Livestock keeping": "Ufugaji",
      "Personal Information": "Taarifa za kibinafsi",
      Practicing: "Shughuli",
      Survey: "Utafiti",
      "First name": "Jina la kwanza",
      "Last name": "Jina la mwisho",
      Gender: "Jinsia",
      "Date of birth": "Siky ya kuzaliwa",
      "Phone number": "Nambari ya simu",
      Email: "Barua pepe",
      Address: "Anuani",
      Region: "Mkoa",
      District: "Wilaya",
      Ward: "Kata",
      Street: "Mtaa",
      "Select a region": "Chagua mkoa",
      "Select a district": "Chagua wilaya",
      "Education level": "Kiwango cha elimu",
      Primary: "Elimu ya msingi",
      Secondary: "Elimu ya sekondari",
      "Vocational training": "Mafunzo ya ufundi",
      Diploma: "Diploma",
      Undergraduate: "Shahada ya kwanza",
      Postgraduate: "Shahada ya pili",
      "Employment status": "Hali ya kazi",
      Retired: "Mstaafu",
      Employed: "Mwajiriwa",
      "Self employed": "Mjasiriamali",
      "Not employed": "Sina hajira",
      "Identification type": "Aina ya kitambulisho",
      "National Identification Card": "Kitambulisho cha taifa",
      "Driver's license": "Leseni ya udereva",
      Passport: "Pasipoti",
      "Voter's Identification Card": "Kitambulisho cha kura",
      "Are you a member of the National Health Insurance Fund(NHIF)":
        "Je, wewe ni mwanachama wa Mfuko wa Bima ya Afya(NHIF)?",
      Yes: "Ndio",
      No: "Hapana",
      Continue: "Endelea",
      //second step
      "Are you currently doing any form of Agriculture?":
        "Je, unafanya shughuli yoyote ya kilimo?",
      "Which of these fields in Agriculture are you participating":
        "Je, unajishughulisha na vitengo gani vya kilimo",
      Farming: "Kilimo",
      Livestock: "Ufugaji",
      Fishing: "Uvuvi",
      "What activities are you involved in?": "Je, unafanya shughuli gani?",
      "What regions do you practice the above activities in":
        "Je, unafanya shughuli kwenye mikoa gani?",
      "What challenges do you face?": "Je, unapambana na changamoto zipi?",
      "Crop production": "Uzalishaji wa mazao",
      "Livestock production": "Uzalishaji wa mifugo",
      "Fish production": "Uzalishaji wa samaki",
      Logistics: "Usafirishaji",
      "Agricultural inputs": "Pembejeo za kilimo",
      "Bucket seeds": "Mbegu",
      "Agricultural tools": "Zana za kilimo",
      "Sales & Marketing": "Uuzaji na utangazaji",
      Previous: "Rudi nyuma",
      "Which of these fields in Agriculture are you interested in?":
        "Je, unapenda kujishughulisha na kitengo gani cha kilimo?",
      "What activity do you wish to be invloved in?":
        "Je, ungependa kufanya shughuli ya aina gani?",
      "What region do you wish to practice in?":
        "Je, unapenda kufanya shughuli hiyo kwenye mikoa gani?",
      "Nida number": "Nambari ya kitambulisho cha taifa",
      "Passport number": "Nambari ya Pasipoti",
      "Voter's Identification number": "Nambari ya kitambulisho cha kura",
      Male: "Mwanaume",
      Female: "Mwanamke",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bon retour",
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welkom terug",
    },
  },
};

const lang = window.localStorage.getItem("kilimo-lang");

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
